import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import MMDLoader from 'components/MMDLoader';
import { getFirebaseImage } from 'utils/Firebase';
import ProviderCategoryForm from './ProviderCategoryForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectProviderCategoryById,
  selectProviderCategoriesState,
  UpdateProviderCategoryPayload,
  Creators as ProviderCategoriesActions,
} from 'modules/provider-categories.module';
import {
  Creators as ProfileTypesActions,
  selectProfileTypesState,
} from 'modules/profile-types.module';

const EditProviderCategory = () => {
  const { id } = useParams();

  const providerCategoryToEdit = useSelector((state) =>
    selectProviderCategoryById(state, id),
  );
  const { loading } = useSelector(selectProviderCategoriesState);

  const { loading: isLoadingProfileTypes } = useSelector(
    selectProfileTypesState,
  );
  const [downloadedImage, setDownloadedImage] = useState(undefined);

  useEffect(() => {
    if (!providerCategoryToEdit || downloadedImage) {
      return;
    }

    const { id, categoryImage } = providerCategoryToEdit;

    const path = `provider-categories/${id}/${categoryImage.fileName}`;

    getFirebaseImage(path).then(setDownloadedImage).catch(console.error);
  }, [providerCategoryToEdit, downloadedImage]);

  const { requestUpdateProviderCategory, requestProfileTypes } =
    useBoundedActions({ ...ProviderCategoriesActions, ...ProfileTypesActions });

  const onEditOrganizationType = (values: UpdateProviderCategoryPayload) =>
    requestUpdateProviderCategory({
      ...values,
      id,
      categoryImage: providerCategoryToEdit.categoryImage,
    });

  useEffect(() => {
    requestProfileTypes();
  }, []);

  return (
    <div className="container p-2">
      <MMDLoader
        loading={!providerCategoryToEdit || loading || isLoadingProfileTypes}
      />

      {providerCategoryToEdit && downloadedImage && (
        <ProviderCategoryForm
          downloadedImage={downloadedImage}
          onSubmit={onEditOrganizationType}
          providerCategory={providerCategoryToEdit}
          isEdit
        />
      )}
    </div>
  );
};

export default EditProviderCategory;
