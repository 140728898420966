import { getAuth } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import {
  deleteObject,
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
} from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  databaseUrl: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(config);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export const getFirebaseImage = async (url: string) => {
  try {
    const pathReference = ref(storage, url);
    if (pathReference) {
      return await getDownloadURL(pathReference);
    }

    return null;
  } catch (e) {
    console.log({ e });
  }
};

export const deleteProviderImage = async (url: string, withModal = true) => {
  try {
    const pathReference = ref(storage, url);
    const image = await deleteObject(pathReference);
    if (withModal) {
      alert('image succesfully deleted');
    }
    return image;
  } catch (e) {
    console.log({ e });
  }
};

export const uploadImageHelper = (uri, image) => {
  try {
    return uploadBytes(ref(storage, uri), image);
  } catch (error) {
    console.log(error);
  }
};
