import {
  FC,
  useState,
  Dispatch,
  HTMLProps,
  useEffect,
  ChangeEvent,
  SetStateAction,
} from 'react';

import { Category } from 'typings';

type MMDSelectType = 'generic' | 'form';

type PropTypes = {
  label: string;
  valid?: boolean;
  dirty?: boolean;
  value?: string;
  categories?: Category[];
  options?: Category[];
  type?: MMDSelectType;
  setCategoriesIds: Dispatch<SetStateAction<string[]>>;
  onExternalChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
} & HTMLProps<HTMLSelectElement>;

const MMDSelectCat: FC<PropTypes> = ({
  setCategoriesIds,
  type = 'form',
  label,
  onChange,
  onExternalChange = undefined,
  valid = true,
  dirty = true,
  categories = [],
  options = [],
  ...rest
}) => {
  const categoriesMap = options.reduce(
    (map, item) => ({ ...map, [item.id]: item.name }),
    [],
  );

  const [selectedIds, setSelectedIds] = useState(
    categories.map((item) => item.id.toString()),
  );

  useEffect(() => {
    setCategoriesIds(selectedIds);
  }, [selectedIds]);

  const handleDelete = (value) => {
    setSelectedIds((prevState) => prevState.filter((id) => id !== value));
  };

  const handleChange = (value) => {
    if (!selectedIds.includes(value)) {
      setSelectedIds([...selectedIds, value]);
    }
  };

  return (
    <div className="field">
      <label className="label">{label}</label>
      <div style={{ display: 'flex' }} className="control">
        <div style={{ width: '150px', minWidth: '150px' }} className="select">
          <select
            style={{ width: '150px', minWidth: '150px' }}
            onChange={(e) => handleChange(e.target.value)}
            {...rest}
          >
            {type === 'form' && <option>{label}</option>}
            {options.map((op) => (
              <option key={`${op.id}-${op.name}`} value={op.id.toString()}>
                {op.name}
              </option>
            ))}
          </select>
        </div>
        <div style={{ display: 'flex' }}>
          {selectedIds.map((selectedId) => (
            <span
              key={selectedId}
              style={{
                marginLeft: '12px',
                borderColor: '#fc105b',
                borderRadius: '20px',
                boxShadow: '0px 0px 3px 0px rgb(252 16 91)',
              }}
              className={`button 'is-primary'`}
              onClick={() => handleDelete(selectedId)}
            >
              {categoriesMap[selectedId]}
              <span
                style={{
                  color: '#fc105b',
                  borderColor: 'solid #fc105b',
                  borderRadius: '20px',
                  marginLeft: '10px',
                  fontSize: '14px',
                }}
              >
                x
              </span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MMDSelectCat;
