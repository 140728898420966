import React, { FC } from 'react';
import { Box, Columns, Media } from 'react-bulma-components';
import { Link } from 'react-router-dom';

import MMDSuperAdminButton from 'components/MMDSuperAdminButton';
import { ProviderCategory } from 'typings';

type Props = {
  targetPath: string;
  category: ProviderCategory;
  onDelete: () => void;
};

const ProviderCategoryRow: FC<Props> = ({ onDelete, category, targetPath }) => {
  return (
    <Box>
      <Media>
        <Media.Item>
          <strong>{category.name}</strong>
        </Media.Item>
      </Media>
      <Columns>
        <Columns.Column size={1} className="mr-3">
          <Link to={`/${targetPath}/${category.id}`}>
            <MMDSuperAdminButton className="mt-2" isPrimary text="Edit" />
          </Link>
        </Columns.Column>
        <Columns.Column size={1}>
          <MMDSuperAdminButton
            isPrimary
            text="Delete"
            className="mt-2"
            onClick={onDelete}
          />
        </Columns.Column>
      </Columns>
    </Box>
  );
};

export default ProviderCategoryRow;
