import { FC, HTMLProps } from 'react';

type PropTypes = Omit<HTMLProps<HTMLInputElement>, 'value'> & {
  label: string;
  dirty?: boolean;
  value?: 'true' | 'false' | string;
};

const MMDCheckbox: FC<PropTypes> = ({
  label,
  dirty = true,
  value = 'false',
  onChange,
  className,
  ...rest
}) => (
  <div className={`control ${className}`}>
    <label className="mr-2">{label}</label>

    <input
      type="checkbox"
      checked={value === 'true'}
      className={`mr-2 ${rest.readOnly ? 'no-click-event' : ''}`}
      onChange={(e) => {
        const nextValue = value === 'true' ? 'false' : 'true';
        const ev = { target: { name: rest.name, value: nextValue } };
        onChange(ev as any);
      }}
      {...rest}
    />
  </div>
);

export default MMDCheckbox;
