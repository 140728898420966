import { FC } from 'react';
import { useForm } from 'react-hook-form';

import { ProviderCategory } from 'typings';
import MMDButton from 'components/MMDButton';
import MMDTextInput from 'components/forms/MMDTextInput';
import MMDImageUploader from 'components/forms/MMDImageUploader';
import { CreateProviderCategoryPayload } from 'modules/provider-categories.module';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import { useSelector } from 'react-redux';
import { selectProfileTypes } from 'modules/profile-types.module';

interface Props {
  providerCategory?: ProviderCategory;
  isEdit?: boolean;
  onSubmit: (values: CreateProviderCategoryPayload) => void;
  downloadedImage?: string;
}

const ProviderCategoryForm: FC<Props> = ({
  providerCategory,
  isEdit = false,
  onSubmit,
  downloadedImage,
}) => {
  const initialProfileTypes = providerCategory
    ? providerCategory.profileTypes.map(({ id }) => id)
    : [];

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    watch,
    setValue,
    formState: { errors },
  } = useForm<{
    name: string;
    image: FileList;
    profileTypeIds: number[];
  }>({
    defaultValues: {
      name: providerCategory?.name ?? '',
      image: undefined,
      profileTypeIds: initialProfileTypes,
    },
  });

  const profileTypes = useSelector(selectProfileTypes);

  const hasFormErrors = Object.keys(errors).length > 0;

  const profileTypeIds = watch('profileTypeIds');

  const getOnSetProfileType =
    (profileTypeId: number) =>
    ({ target }) => {
      const newTypes =
        target.value === 'true'
          ? [...profileTypeIds, profileTypeId]
          : profileTypeIds.filter((type) => type !== profileTypeId);
      return setValue('profileTypeIds', newTypes, {
        shouldValidate: true,
      });
    };

  const isTypeSelected = (id: number) =>
    profileTypeIds.includes(id) ? 'true' : 'false';

  register('profileTypeIds', {
    required: true,
    validate: (value) => value.length >= 1,
  });

  return (
    <form
      onSubmit={handleSubmit(({ name, image, profileTypeIds }) =>
        onSubmit({ name, image: image.item(0), profileTypeIds }),
      )}
    >
      <MMDTextInput
        required
        label="Name"
        valid={!errors.name}
        {...register('name', {
          required: true,
          setValueAs: (value: string) => value.trim(),
        })}
      />

      <MMDImageUploader
        label="Picture"
        required={!isEdit}
        initialPreview={downloadedImage}
        clearErrors={clearErrors}
        setError={setError}
        error={errors?.image?.message}
        register={register}
        name="image"
      />

      <div>
        <strong className="required">Select allowed profile types</strong>

        {profileTypes.map((item) => {
          return (
            <MMDCheckbox
              key={item.id}
              className="mt-2"
              label={item.type}
              value={isTypeSelected(item.id)}
              name="profileTypeIds"
              onChange={getOnSetProfileType(item.id)}
            />
          );
        })}

        {errors['profileTypeIds'] && (
          <p className="has-text-danger">Minimum 1 type is required</p>
        )}
      </div>

      <MMDButton
        isSubmit
        isPrimary
        className="mt-3"
        isDisabled={hasFormErrors}
        text={isEdit ? 'Save changes' : 'Create'}
      />
    </form>
  );
};

export default ProviderCategoryForm;
