import { Action } from 'redux';
import { MyState } from 'store';
import { createSelector } from 'reselect';
import { createActions, createReducer } from 'reduxsauce';
import {
  MyAction,
  EntityState,
  ErrorAction,
  composeSagas,
  createEntityAdapter,
  createSingleEventSaga,
} from '@mrnkr/redux-saga-toolbox';

import { API_URL } from 'config';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { ProviderCategory, SagaIterator } from 'typings';
import { deleteProviderImage, uploadImageHelper } from 'utils/Firebase';
import { downloadUsingLocationQuery } from 'utils/downloadUsingLocationQuery';
import {
  Paginated,
  CreationResult,
  ArgsWithHeaders,
  LocationChangeActionPayload,
} from 'utils/typings';
import {
  onRoute,
  UNAUTHORIZED,
  goBackFactory,
  extractRouteParams,
  INVISIBLE_ERROR_MESSAGE,
} from 'utils/onRoute';

interface ActionTypes {
  REQUEST_CREATE_PROVIDER_CATEGORY: string;
  REQUEST_UPDATE_PROVIDER_CATEGORY: string;
  REQUEST_DELETE_PROVIDER_CATEGORY: string;
  LOADING_PROVIDER_CATEGORIES: string;
  COMMIT_PROVIDER_CATEGORY: string;
  COMMIT_PROVIDER_CATEGORIES: string;
  REMOVE_PROVIDER_CATEGORY: string;
  ERROR_PROVIDER_CATEGORIES: string;
}

interface ActionCreators {
  requestCreateProviderCategory: (
    payload: CreateProviderCategoryPayload,
  ) => MyAction<CreateProviderCategoryPayload>;
  requestUpdateProviderCategory: (
    payload: Partial<ProviderCategory>,
  ) => MyAction<Partial<ProviderCategory>>;
  requestDeleteProviderCategory: (
    payload: Partial<{ id: number }>,
  ) => MyAction<Partial<{ id: number }>>;
  loadingProviderCategories: () => Action;
  commitProviderCategories: (
    payload: Paginated<ProviderCategory>,
  ) => MyAction<Paginated<ProviderCategory>>;
  commitProviderCategory: (
    payload: ProviderCategory,
  ) => MyAction<ProviderCategory>;
  removeProviderCategory: (payload: { id: number }) => MyAction<{ id: number }>;
  errorProviderCategories: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface ProviderCategoriesState<TError extends Error = Error>
  extends EntityState<ProviderCategory> {
  loading: boolean;
  count: number;
  error?: TError;
}

export type CreateProviderCategoryPayload = {
  name: string;
  image: File;
  profileTypeIds: number[];
};

export type UpdateProviderCategoryPayload = {
  name: string;
  image?: File;
  profileTypeIds: number[];
};

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestCreateProviderCategory: ['payload'],
  requestUpdateProviderCategory: ['payload'],
  requestDeleteProviderCategory: ['payload'],
  loadingProviderCategories: [],
  commitProviderCategories: ['payload'],
  commitProviderCategory: ['payload'],
  removeProviderCategory: ['payload'],
  errorProviderCategories: ['error'],
});

const entityAdapter = createEntityAdapter<ProviderCategory>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});
export const providerCategoriesSelector = entityAdapter.getSelectors();

function setLoading(state: ProviderCategoriesState): ProviderCategoriesState {
  return {
    ...state,
    loading: true,
  };
}

function commitProviderCategories(
  state: ProviderCategoriesState,
  action: MyAction<Paginated<ProviderCategory>>,
): ProviderCategoriesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

function commitProviderCategory(
  state: ProviderCategoriesState,
  action: MyAction<ProviderCategory>,
): ProviderCategoriesState {
  return {
    ...entityAdapter.upsertOne(action.payload, state),
    loading: false,
  };
}

function setError<TError extends Error = Error>(
  state: ProviderCategoriesState,
  { error }: ErrorAction<TError>,
): ProviderCategoriesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

function removeProviderCategory(
  state: ProviderCategoriesState,
  action: MyAction<{ id: number }>,
): ProviderCategoriesState {
  return {
    ...entityAdapter.removeOne(action.payload.id.toString(), state),
    loading: false,
  };
}

export const providerCategoriesReducer = createReducer(initialState, {
  [Types.LOADING_PROVIDER_CATEGORIES]: setLoading,
  [Types.COMMIT_PROVIDER_CATEGORIES]: commitProviderCategories,
  [Types.ERROR_PROVIDER_CATEGORIES]: setError,
  [Types.COMMIT_PROVIDER_CATEGORY]: commitProviderCategory,
  [Types.REMOVE_PROVIDER_CATEGORY]: removeProviderCategory,
});

async function requestProviderCategory({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: number }>): Promise<ProviderCategory> {
  const result = await fetch(`${API_URL}/categories?id=${payload.id}`, {
    headers,
    method: 'GET',
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

const uploadCategoryImage = async (id: number, image: File) => {
  const path = `provider-categories/${id}/${image.name}`;
  await uploadImageHelper(path, image);
};

async function createProviderCategory({
  headers,
  name,
  image,
  profileTypeIds,
}: ArgsWithHeaders<CreateProviderCategoryPayload>): Promise<
  CreationResult<any>
> {
  const result = await fetch(`${API_URL}/categories`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ name, ids: profileTypeIds }),
  });
  const res = await result.json();

  await uploadCategoryImage(res.createdId, image);
  await fetch(`${API_URL}/categories/files/${res.createdId}`, {
    headers,
    method: 'POST',
    body: JSON.stringify({ fileName: image.name }),
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return res;
}

async function updateProviderCategory({
  headers,
  name,
  image,
  id,
  categoryImage,
  profileTypeIds,
}: ArgsWithHeaders<
  UpdateProviderCategoryPayload & { id: string } & Pick<
      ProviderCategory,
      'categoryImage'
    >
>): Promise<void> {
  const result = await fetch(`${API_URL}/categories/${id}`, {
    headers,
    method: 'PUT',
    body: JSON.stringify({ name, ids: profileTypeIds }),
  });

  if (image) {
    await fetch(`${API_URL}/categories/files/${id}/${categoryImage.fileName}`, {
      headers,
      method: 'DELETE',
    });

    await deleteProviderImage(
      `provider-categories/${id}/${categoryImage.fileName}`,
      false,
    );

    await uploadCategoryImage(Number(id), image);
    await fetch(`${API_URL}/categories/files/${id}`, {
      headers,
      method: 'POST',
      body: JSON.stringify({ fileName: image.name }),
    });
  }

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

async function deleteProviderCategory({
  headers,
  ...payload
}: ArgsWithHeaders<{ id: number; fileName: string }>): Promise<void> {
  const result = await fetch(`${API_URL}/categories/${payload.id}`, {
    headers,
    method: 'DELETE',
  });

  await fetch(`${API_URL}/categories/files/${payload.id}/${payload.fileName}`, {
    headers,
    method: 'DELETE',
  });

  await deleteProviderImage(
    `provider-categories/${payload.id}/${payload.fileName}`,
    false,
  );

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }
}

const requestProviderCategoryWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  ProviderCategory,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/categories/:id'),
  loadingAction: Creators.loadingProviderCategories,
  commitAction: Creators.commitProviderCategory,
  successAction: noOpAction,
  errorAction: Creators.errorProviderCategories,
  action: requestProviderCategory,
  beforeAction: composeSagas<
    LocationChangeActionPayload,
    { id: number },
    ArgsWithHeaders<{ id: number }>
  >(extractRouteParams('/categories/:id'), putAuthInfoInArgs),
  *afterAction(res?: Paginated<ProviderCategory>): SagaIterator {
    return res ? res.data.pop() : undefined;
  },
});

const goBack = goBackFactory('/categories');

const requestCreateProviderCategoryWatcher = createSingleEventSaga<
  ProviderCategory,
  ProviderCategory,
  MyAction<ProviderCategory>
>({
  takeEvery: Types.REQUEST_CREATE_PROVIDER_CATEGORY,
  loadingAction: Creators.loadingProviderCategories,
  commitAction: Creators.commitProviderCategory,
  successAction: goBack.action,
  errorAction: Creators.errorProviderCategories,
  action: createProviderCategory,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    res: CreationResult<ProviderCategory>,
    { headers, ...args }: ArgsWithHeaders<ProviderCategory>,
  ): SagaIterator {
    return {
      ...args,
      id: res.createdId.toString(),
    };
  },
});

const requestUpdateProviderCategoryWatcher = createSingleEventSaga<
  Partial<ProviderCategory>,
  Partial<ProviderCategory>,
  MyAction<Partial<ProviderCategory>>
>({
  takeEvery: Types.REQUEST_UPDATE_PROVIDER_CATEGORY,
  loadingAction: Creators.loadingProviderCategories,
  commitAction: Creators.commitProviderCategory,
  successAction: goBack.action,
  errorAction: Creators.errorProviderCategories,
  action: updateProviderCategory,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<ProviderCategory>,
  ): SagaIterator {
    return args;
  },
});

const requestDeleteProviderCategoryWatcher = createSingleEventSaga<
  { id: number },
  void,
  MyAction<{ id: number }>
>({
  takeEvery: Types.REQUEST_DELETE_PROVIDER_CATEGORY,
  loadingAction: Creators.loadingProviderCategories,
  commitAction: Creators.removeProviderCategory,
  successAction: noOpAction,
  errorAction: Creators.errorProviderCategories,
  action: deleteProviderCategory,
  beforeAction: putAuthInfoInArgs,
  *afterAction(
    _,
    { headers, ...args }: ArgsWithHeaders<{ id: number }>,
  ): SagaIterator {
    return args;
  },
});

const requestProviderCategoriesWatcher = createSingleEventSaga<
  LocationChangeActionPayload,
  Paginated<ProviderCategory>,
  MyAction<LocationChangeActionPayload>
>({
  takeEvery: onRoute('/categories'),
  loadingAction: Creators.loadingProviderCategories,
  commitAction: Creators.commitProviderCategories,
  successAction: noOpAction,
  errorAction: Creators.errorProviderCategories,
  action: downloadUsingLocationQuery<ProviderCategory>('categories'),
  beforeAction: putAuthInfoInArgs,
});

export const providerCategoriesSagas = [
  requestProviderCategoriesWatcher,
  requestProviderCategoryWatcher,
  requestCreateProviderCategoryWatcher,
  requestUpdateProviderCategoryWatcher,
  requestDeleteProviderCategoryWatcher,
  goBack.watcher,
];

export const selectProviderCategoriesState = (state: MyState) =>
  state.providerCategories;

export const selectProviderCategoryById = createSelector(
  [selectProviderCategoriesState, (_, id) => id],
  (state, id) => state.entities[id],
);

export const selectProviderCategories = createSelector(
  selectProviderCategoriesState,
  (state) => providerCategoriesSelector.selectAll(state),
);
