import { useSelector } from 'react-redux';

import EntitiesTable from 'components/entities-table';
import ProviderCategoryRow from './ProviderCategoryRow';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectProviderCategories,
  selectProviderCategoriesState,
  Creators as ProviderCategoriesActions,
} from 'modules/provider-categories.module';

const ProviderCategories = () => {
  const { loading, count } = useSelector(selectProviderCategoriesState);

  const questionnaires = useSelector(selectProviderCategories);

  const { requestDeleteProviderCategory } = useBoundedActions(
    ProviderCategoriesActions,
  );

  return (
    <EntitiesTable
      count={count}
      withoutSearch
      loading={loading}
      withoutApproveFilter
      rootPath="categories"
      newEntityUrl="create-category"
      elements={questionnaires.map((category) => (
        <ProviderCategoryRow
          targetPath="categories"
          key={category.id}
          category={category}
          onDelete={() =>
            requestDeleteProviderCategory({
              id: category.id,
              fileName: category.categoryImage.fileName,
            })
          }
        />
      ))}
    />
  );
};

export default ProviderCategories;
