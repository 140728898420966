import Patient from '../../components/patient';
import Provider from '../../components/provider';
import LoginForm from '../login';
import ChangePasswordForm from '../ChangePasswordForm';
import Patients from '../Patients';
import Providers from '../Providers';
import PushNotifications from '../PushNotifications';
import NotificationEdit from '../../components/notifications';
import PromoCodes from '../PromoCodes';
import Transactions from '../Transactions';
import PaymentTransactions from '../PaymentTransactions';
import PromoCodeForm from '../PromoCodeForm';
import { MenuItem } from '../../utils/typings';
import Pharmacies from '../pharmacies';
import PharmacyForm from 'pages/PharmacyForm';
import Medications from '../Medications';
import MedicationsForm from '../MedicationForm';
import Specialities from 'pages/Specialities';
import Credentials from 'pages/Credentials';
import SpecialitiesForm from '../SpecialityForm';
import Affiliations from '../Affiliations';
import CredentialsForm from '../CredentialForm';
import TemplatesForm from '../TemplateForm';
import AffiliationsForm from '../AffiliationForm';
import Conditions from '../Conditions';
import ConditionsForm from '../ConditionForm';
import Allergies from '../Allergies';
import AllergyForm from '../AllergyForm';
import PaymentConfig from '../PaymentConfig';
import TermsAndConditions from '../TermsAndConditions';
import TermsAndConditionsForm from '../TermsAndConditionsForm';
import PrivacyPolicy from '../PrivacyPolicy';
import PrivacyPolicyForm from '../PrivacyPolicyForm';
import PreviewHtml from 'pages/PreviewHtml';
import Prescriptions from 'pages/Prescriptions';
import Sessions from 'pages/Sessions';
import SigninAttempts from 'pages/SigninAttempts';
import Logs from 'pages/Logs';
import LicensedStates from 'pages/LicensedStates';
import LicensedStateForm from 'pages/licensed-state/LicensedStateForm';
import ConvertToStudent from 'pages/ConvertToStudent';
import Templates from 'pages/Templates';
import { ComponentType } from 'react';
import Bookings from 'pages/bookings';
import Booking from 'pages/bookings/Booking';
import UserInvitation from 'pages/user-invitation';
import Users from 'pages/Users';
import InviteUser from 'pages/InviteUser';
import EditUser from 'pages/edit-user';
import AdminLogs from 'pages/AdminLogs';
import Invites from 'pages/Invites';
import Availability from 'pages/availability';
import ResetPassword from 'pages/reset-password';
import PublicBookingDurations from 'pages/public-booking-durations';
import NewDuration from 'pages/public-booking-durations/NewDuration';
import EditDuration from 'pages/public-booking-durations/EditDuration';
import PatientConsent from 'pages/patient-consent';
import NewPatientConsent from 'pages/patient-consent/NewPatientConsent';
import EditPatientConsent from 'pages/patient-consent/EditPatientConsent';
import Organizations from 'pages/organizations';
import NewOrganization from 'pages/organizations/NewOrganization';
import EditOrganization from 'pages/organizations/EditOrganization';
import BookingsPublic from 'pages/bookings-public';
import BookingPublic from 'pages/bookings-public/BookingPublic';
import Immunizations from 'pages/immunizations';
import NewImmunization from 'pages/immunizations/NewImmunization';
import EditImmunization from 'pages/immunizations/EditImmunization';
import PatientImmunizations from 'pages/immunizations/PatientImmunizations';
import Questionnaire from 'pages/questionnaire';
import NewQuestionnaire from 'pages/questionnaire/NewQuestionnaire';
import PreviewQuestionnaire from 'pages/questionnaire/PreviewQuestionnaire';
import OrganizationTypes from 'pages/organization-types';
import NewOrganizationType from 'pages/organization-types/NewOrganizationType';
import EditOrganizationType from 'pages/organization-types/EditOrganizationType';
import ProviderLanguages from 'pages/provider-languages';
import EditProviderLanguage from 'pages/provider-languages/EditProviderLanguage';
import NewProviderLanguage from 'pages/provider-languages/NewProviderLanguage';
import ProviderCategories from 'pages/provider-categories';
import NewProviderCategory from 'pages/provider-categories/NewProviderCategory';
import EditProviderCategory from 'pages/provider-categories/EditProviderCategory';

export interface Route {
  path: string;
  component: ComponentType<any>;
  showMenuItem: boolean;
  menuItem?: (navigate: (path: string) => void) => MenuItem;
}

export const routesWithoutAuth = ['/user-invitation', '/reset-password'];

export const routes: Route[] = [
  {
    path: '/user-invitation',
    component: UserInvitation,
    showMenuItem: false,
  },
  {
    path: '/',
    component: LoginForm,
    showMenuItem: false,
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    showMenuItem: false,
  },
  {
    path: '/providers',
    component: Providers,
    showMenuItem: true,
    menuItem: (navigate) => ({
      text: 'Providers',
      onPress: () => navigate('/providers'),
      items: [
        {
          text: 'Categories',
          onPress: () => navigate('/categories'),
        },
        {
          text: 'Specialties',
          onPress: () => navigate('/specialities'),
        },
        {
          text: 'Credentials',
          onPress: () => navigate('/credentials'),
        },
        {
          text: 'Affiliations',
          onPress: () => navigate('/affiliations'),
        },
        {
          text: 'Prescriptions',
          onPress: () => navigate('/prescriptions'),
        },
        // TODO not implemented on BE
        // {
        //   text: 'Template Notes',
        //   onPress: () => navigate('/templates'),
        // },
        {
          text: 'Payment configuration',
          path: '/payment-config',
          onPress: () => navigate('/payment-config'),
        },
        {
          text: 'Public booking durations',
          path: '/public-booking-durations',
          onPress: () => navigate('/public-booking-durations'),
        },
        {
          text: 'Languages',
          path: '/provider-languages',
          onPress: () => navigate('/provider-languages'),
        },
      ],
    }),
  },
  {
    path: '/categories',
    component: ProviderCategories,
    showMenuItem: false,
  },
  {
    path: '/categories/:id',
    component: EditProviderCategory,
    showMenuItem: false,
  },
  {
    path: '/create-category',
    component: NewProviderCategory,
    showMenuItem: false,
  },
  {
    path: '/payment-config',
    component: PaymentConfig,
    showMenuItem: false,
  },
  {
    path: '/prescriptions',
    component: Prescriptions,
    showMenuItem: false,
  },
  {
    path: '/public-booking-durations',
    component: PublicBookingDurations,
    showMenuItem: false,
  },
  {
    path: '/public-booking-durations/:id',
    component: EditDuration,
    showMenuItem: false,
  },
  {
    path: '/create-public-booking-duration',
    component: NewDuration,
    showMenuItem: false,
  },
  //TODO not implemented on BE
  // {
  //   path: '/templates',
  //   component: Templates,
  //   showMenuItem: false,
  // },
  {
    path: '/templates/:id',
    component: TemplatesForm,
    showMenuItem: false,
  },
  {
    path: '/create-templates',
    component: TemplatesForm,
    showMenuItem: false,
  },
  {
    path: '/users',
    component: Users,
    showMenuItem: false,
  },
  {
    path: '/invites',
    component: Invites,
    showMenuItem: false,
  },
  {
    path: '/users/:id',
    component: EditUser,
    showMenuItem: false,
  },
  {
    path: '/admin-logs',
    component: AdminLogs,
    showMenuItem: false,
  },
  {
    path: '/invite-user',
    component: InviteUser,
    showMenuItem: false,
  },
  {
    path: '/providers/:id',
    component: Provider,
    showMenuItem: false,
  },
  {
    path: '/providers/:id/transactions',
    component: Transactions,
    showMenuItem: false,
  },
  {
    path: '/providers/:id/payment-transactions',
    component: PaymentTransactions,
    showMenuItem: false,
  },
  {
    path: '/providers/:id/licensed-states',
    component: LicensedStates,
    showMenuItem: false,
  },
  {
    path: '/providers/:id/availability',
    component: Availability,
    showMenuItem: false,
  },
  {
    path: '/providers/:id/create-licensed-states',
    component: LicensedStateForm,
    showMenuItem: false,
  },
  {
    path: '/providers/:id/licensed-states/:idLicensedState',
    component: LicensedStateForm,
    showMenuItem: false,
  },
  {
    path: '/providers/:id/bookings',
    component: Bookings,
    showMenuItem: false,
  },
  {
    path: '/providers/:id/bookings/:bookingId',
    component: Booking,
    showMenuItem: false,
  },
  {
    path: '/providers/:id/bookings-public',
    component: BookingsPublic,
    showMenuItem: false,
  },
  {
    path: '/providers/:id/bookings-public/:bookingId',
    component: BookingPublic,
    showMenuItem: false,
  },
  {
    path: '/providers/:id/sessions',
    component: Sessions,
    showMenuItem: false,
  },
  {
    path: '/patients',
    component: Patients,
    showMenuItem: true,
    menuItem: (navigate) => ({
      text: 'Patients',
      onPress: () => navigate('/patients'),
      items: [
        {
          text: 'Conditions',
          onPress: () => navigate('/conditions'),
        },
        {
          text: 'Allergies ',
          onPress: () => navigate('/allergies'),
        },
        {
          text: 'Pharmacies',
          onPress: () => navigate('/pharmacies'),
        },
        {
          text: 'Organizations',
          onPress: () => navigate('/organizations'),
        },
        {
          text: 'Organization Types',
          onPress: () => navigate('/organization-types'),
        },
        {
          text: 'Immunizations',
          onPress: () => navigate('/immunizations'),
        },
        {
          text: 'Questionnaire',
          onPress: () => navigate('/questionnaire'),
        },
      ],
    }),
  },
  {
    path: '/provider-languages',
    component: ProviderLanguages,
    showMenuItem: false,
  },
  {
    path: '/provider-languages/:id',
    component: EditProviderLanguage,
    showMenuItem: false,
  },
  {
    path: '/create-provider-language',
    component: NewProviderLanguage,
    showMenuItem: false,
  },
  {
    path: '/questionnaire',
    component: Questionnaire,
    showMenuItem: false,
  },
  {
    path: '/create-questionnaire',
    component: NewQuestionnaire,
    showMenuItem: false,
  },
  {
    path: '/questionnaire-preview/:id',
    component: PreviewQuestionnaire,
    showMenuItem: false,
  },
  {
    path: '/organizations',
    component: Organizations,
    showMenuItem: false,
  },
  {
    path: '/organization-types',
    component: OrganizationTypes,
    showMenuItem: false,
  },
  {
    path: '/organization-types/:id',
    component: EditOrganizationType,
    showMenuItem: false,
  },
  {
    path: '/create-organization-type',
    component: NewOrganizationType,
    showMenuItem: false,
  },
  {
    path: '/organizations/:id',
    component: EditOrganization,
    showMenuItem: false,
  },
  {
    path: '/create-organization',
    component: NewOrganization,
    showMenuItem: false,
  },
  {
    path: '/immunizations',
    component: Immunizations,
    showMenuItem: false,
  },
  {
    path: '/immunizations/:id',
    component: EditImmunization,
    showMenuItem: false,
  },
  {
    path: '/create-immunization',
    component: NewImmunization,
    showMenuItem: false,
  },
  {
    path: '/patients/:id',
    component: Patient,
    showMenuItem: false,
  },
  {
    path: '/patients/:id/immunizations',
    component: PatientImmunizations,
    showMenuItem: false,
  },
  {
    path: '/patients/:id/transactions',
    component: Transactions,
    showMenuItem: false,
  },
  {
    path: '/patients/:id/bookings',
    component: Bookings,
    showMenuItem: false,
  },
  {
    path: '/patients/:id/bookings/:bookingId',
    component: Booking,
    showMenuItem: false,
  },
  {
    path: '/patients/:id/bookings-public',
    component: BookingsPublic,
    showMenuItem: false,
  },
  {
    path: '/patients/:id/bookings-public/:bookingId',
    component: BookingPublic,
    showMenuItem: false,
  },
  {
    path: '/patients/:id/sessions',
    component: Sessions,
    showMenuItem: false,
  },
  {
    path: '/push-notifications',
    component: PushNotifications,
    showMenuItem: true,
    menuItem: (navigate) => ({
      text: 'Push Notifications',
      onPress: () => navigate('/push-notifications'),
    }),
  },
  {
    path: '/push-notifications/:id',
    component: NotificationEdit,
    showMenuItem: false,
  },
  {
    path: '/promo-codes',
    component: PromoCodes,
    showMenuItem: true,
    menuItem: (navigate) => ({
      text: 'Promo Codes',
      onPress: () => navigate('/promo-codes'),
    }),
  },
  {
    path: '/promo-codes/:id',
    component: PromoCodeForm,
    showMenuItem: false,
  },
  {
    path: '/create-promo-code',
    component: PromoCodeForm,
    showMenuItem: false,
  },
  {
    path: '/pharmacies',
    component: Pharmacies,
    showMenuItem: false,
  },
  {
    path: '/medications',
    component: Medications,
    showMenuItem: true,
    menuItem: (navigate) => ({
      text: 'Medications ',
      onPress: () => navigate('/medications'),
    }),
  },
  {
    path: '/pharmacies/:id',
    component: PharmacyForm,
    showMenuItem: false,
  },
  {
    path: '/medications/:id',
    component: MedicationsForm,
    showMenuItem: false,
  },
  {
    path: '/create-medication',
    component: MedicationsForm,
    showMenuItem: false,
  },
  {
    path: '/create-pharmacy',
    component: PharmacyForm,
    showMenuItem: false,
  },
  {
    path: '/specialities',
    component: Specialities,
    showMenuItem: false,
  },
  {
    path: '/credentials',
    component: Credentials,
    showMenuItem: false,
  },
  {
    path: '/credentials/:id',
    component: CredentialsForm,
    showMenuItem: false,
  },
  {
    path: '/create-credential',
    component: CredentialsForm,
    showMenuItem: false,
  },
  {
    path: '/specialities/:id',
    component: SpecialitiesForm,
    showMenuItem: false,
  },
  {
    path: '/create-speciality',
    component: SpecialitiesForm,
    showMenuItem: false,
  },
  {
    path: '/affiliations',
    component: Affiliations,
    showMenuItem: false,
  },
  {
    path: '/affiliations/:id',
    component: AffiliationsForm,
    showMenuItem: false,
  },
  {
    path: '/create-affiliation',
    component: AffiliationsForm,
    showMenuItem: false,
  },
  {
    path: '/conditions',
    component: Conditions,
    showMenuItem: false,
  },
  {
    path: '/conditions/:id',
    component: ConditionsForm,
    showMenuItem: false,
  },
  {
    path: '/create-condition',
    component: ConditionsForm,
    showMenuItem: false,
  },
  {
    path: '/allergies',
    component: Allergies,
    showMenuItem: false,
  },
  {
    path: '/allergies/:id',
    component: AllergyForm,
    showMenuItem: false,
  },
  {
    path: '/create-allergy',
    component: AllergyForm,
    showMenuItem: false,
  },
  {
    path: '/create-term-and-condition/:userType',
    component: TermsAndConditionsForm,
    showMenuItem: false,
  },
  {
    path: '/create-privacy-policy/:userType',
    component: PrivacyPolicyForm,
    showMenuItem: false,
  },
  {
    path: '/previewHtml',
    component: PreviewHtml,
    showMenuItem: false,
  },
  {
    path: '**nopath*/*',
    component: null,
    showMenuItem: true,
    menuItem: (navigate) => ({
      text: 'System',
      onPress: () => {},
      items: [
        {
          text: 'Privacy policy',
          onPress: () => navigate('/privacy-policy'),
        },
        {
          text: 'Terms and conditions',
          onPress: () => navigate('/terms-and-conditions'),
        },
        {
          text: 'Patient Consent',
          onPress: () => navigate('/patient-consent'),
        },
        {
          text: 'Logs',
          onPress: () => navigate('/logs'),
        },
        {
          text: 'Signin attempts',
          onPress: () => navigate('/signin-attempts'),
        },
        {
          text: 'Change password',
          onPress: () => navigate('/change-password'),
        },
      ],
    }),
  },
  {
    path: '/privacy-policy',
    component: PrivacyPolicy,
    showMenuItem: false,
  },
  {
    path: '/privacy-policy/:id',
    component: PrivacyPolicyForm,
    showMenuItem: false,
  },
  {
    path: '/patient-consent',
    component: PatientConsent,
    showMenuItem: false,
  },
  {
    path: '/patient-consent/:id',
    component: EditPatientConsent,
    showMenuItem: false,
  },
  {
    path: '/create-patient-consent',
    component: NewPatientConsent,
    showMenuItem: false,
  },
  {
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    showMenuItem: false,
  },
  {
    path: '/terms-and-conditions/:id',
    component: TermsAndConditionsForm,
    showMenuItem: false,
  },
  {
    path: '/logs',
    component: Logs,
    showMenuItem: false,
  },
  {
    path: '/signin-attempts',
    component: SigninAttempts,
    showMenuItem: false,
  },
  {
    path: '/change-password',
    component: ChangePasswordForm,
    showMenuItem: false,
  },
  {
    path: '/convert-to-student/:id',
    component: ConvertToStudent,
    showMenuItem: false,
  },
];
