import { ChangeEvent, FC, HTMLProps } from 'react';

type MMDSelectType = 'generic' | 'form';

type PropTypes = {
  label: string;
  valid?: boolean;
  dirty?: boolean;
  value?: string | number;
  options?: any;
  type?: MMDSelectType;
  onExternalChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
} & HTMLProps<HTMLSelectElement>;

const MMDSelect: FC<PropTypes> = ({
  type = 'form',
  label,
  onChange,
  onExternalChange = undefined,
  valid = true,
  dirty = true,
  options = [],
  required,
  ...rest
}) => (
  <div className="field">
    <label className={`label ${required ? 'required' : ''}`}>{label}</label>
    <div className="control">
      <div className="select">
        <select
          onChange={type === 'generic' ? onExternalChange : onChange}
          {...rest}
        >
          {type === 'form' && (
            <option selected value="">
              {label}
            </option>
          )}
          {options[0] &&
            options.map(({ id, label, value, name }) => (
              <option key={`${id}-${value || label}`} value={value || name}>
                {label ?? name}
              </option>
            ))}
        </select>
      </div>
    </div>
  </div>
);

export default MMDSelect;
