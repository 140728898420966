import ProviderCategoryForm from './ProviderCategoryForm';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  Creators as ProviderCategoriesActions,
  selectProviderCategoriesState,
} from 'modules/provider-categories.module';
import { useSelector } from 'react-redux';
import MMDLoader from 'components/MMDLoader';
import {
  Creators as ProfileTypesActions,
  selectProfileTypesState,
} from 'modules/profile-types.module';
import { useEffect } from 'react';

const NewProviderCategory = () => {
  const { requestCreateProviderCategory, requestProfileTypes } =
    useBoundedActions({ ...ProviderCategoriesActions, ...ProfileTypesActions });

  const { loading } = useSelector(selectProviderCategoriesState);

  const { loading: isLoadingProfileTypes } = useSelector(
    selectProfileTypesState,
  );

  useEffect(() => {
    requestProfileTypes();
  }, []);

  return (
    <div className="container p-2">
      <MMDLoader loading={loading || isLoadingProfileTypes} />
      <ProviderCategoryForm onSubmit={requestCreateProviderCategory} />
    </div>
  );
};

export default NewProviderCategory;
