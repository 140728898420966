import {
  createEntityAdapter,
  createSingleEventSaga,
  EntityState,
  ErrorAction,
  MyAction,
} from '@mrnkr/redux-saga-toolbox';
import { Action } from 'redux';
import { ProfileType } from 'typings';
import { createActions, createReducer } from 'reduxsauce';
import { MyState } from 'store';
import { createSelector } from 'reselect';
import { ArgsWithHeaders, Paginated } from 'utils/typings';
import { INVISIBLE_ERROR_MESSAGE, UNAUTHORIZED } from 'utils/onRoute';
import { noOpAction } from 'utils/noOpAction';
import { putAuthInfoInArgs } from './auth.module';
import { API_URL } from 'config';

interface ActionTypes {
  REQUEST_PROFILE_TYPES: string;
  COMMIT_PROFILE_TYPES: string;
  LOADING_PROFILE_TYPES: string;
  ERROR_PROFILE_TYPES: string;
}

interface ActionCreators {
  requestProfileTypes: () => Action;
  loadingProfileTypes: () => Action;
  commitProfileTypes: (
    payload: Paginated<ProfileType>,
  ) => MyAction<Paginated<ProfileType>>;
  errorProfileTypes: <TError extends Error>(
    error: TError,
  ) => ErrorAction<TError>;
}

export interface ProfileTypesState<TError extends Error = Error>
  extends EntityState<ProfileType> {
  loading: boolean;
  count: number;
  error?: TError;
}

export const { Creators, Types } = createActions<ActionTypes, ActionCreators>({
  requestProfileTypes: [],
  loadingProfileTypes: [],
  commitProfileTypes: ['payload'],
  errorProfileTypes: ['error'],
});

const entityAdapter = createEntityAdapter<ProfileType>({
  selectId: (item) => item.id.toString(),
  sortComparer: false,
});

const initialState = entityAdapter.getInitialState({
  loading: false,
  count: 0,
});

export const profileTypesSelectors = entityAdapter.getSelectors();

function setLoading(state: ProfileTypesState): ProfileTypesState {
  return {
    ...state,
    loading: true,
  };
}

function setError<TError extends Error = Error>(
  state: ProfileTypesState,
  { error }: ErrorAction<TError>,
): ProfileTypesState {
  return {
    ...state,
    error,
    loading: false,
  };
}

function commitProfileTypes(
  state: ProfileTypesState,
  action: MyAction<Paginated<ProfileType>>,
): ProfileTypesState {
  return {
    ...entityAdapter.addAll(action.payload.data, state),
    count: action.payload.count,
    loading: false,
  };
}

export const profileTypesReducer = createReducer(initialState, {
  [Types.LOADING_PROFILE_TYPES]: setLoading,
  [Types.ERROR_PROFILE_TYPES]: setError,
  [Types.COMMIT_PROFILE_TYPES]: commitProfileTypes,
});

async function getProfileTypes({ headers }: ArgsWithHeaders): Promise<void> {
  const result = await fetch(`${API_URL}/profile-types`, {
    headers,
  });

  if (!result.ok) {
    if (result.status === UNAUTHORIZED) {
      throw Error(INVISIBLE_ERROR_MESSAGE);
    }

    throw Error('There has been an error processing your request');
  }

  return result.json();
}

const requestProfileTypesWatcher = createSingleEventSaga<
  ProfileType,
  Paginated<ProfileType>,
  MyAction<ProfileType>
>({
  takeEvery: Types.REQUEST_PROFILE_TYPES,
  loadingAction: Creators.loadingProfileTypes,
  commitAction: Creators.commitProfileTypes,
  successAction: noOpAction,
  errorAction: Creators.errorProfileTypes,
  action: getProfileTypes,
  beforeAction: putAuthInfoInArgs,
});

export const profileTypesSagas = [requestProfileTypesWatcher];

export const selectProfileTypesState = (state: MyState) => state.profileTypes;

export const selectProfileTypes = createSelector(
  selectProfileTypesState,
  (state) => profileTypesSelectors.selectAll(state),
);
