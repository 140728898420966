import { FC, HTMLProps, useState } from 'react';
import {
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
} from 'react-hook-form';

type Props = {
  register: UseFormRegister<any>;
  error?: string;
  required?: boolean;
  label: string;
  setError: UseFormSetError<any>;
  clearErrors: UseFormClearErrors<any>;
  maxWidth?: number;
  maxHeight?: number;
  initialPreview?: string;
} & HTMLProps<HTMLInputElement>;

const MMDImageUploader: FC<Props> = ({
  label,
  required = false,
  register,
  error,
  name,
  setError,
  clearErrors,
  maxHeight = 300,
  maxWidth = 1000,
  initialPreview,
  ...rest
}) => {
  const [imagePreview, setImagePreview] = useState(initialPreview);
  const [fileName, setFileName] = useState('');

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const img = new Image();
      img.src = URL.createObjectURL(file);

      img.onload = () => {
        const { width, height } = img;

        if (width > maxWidth || height > maxHeight) {
          setError(name, {
            type: 'manual',
            message: `Image dimensions should not exceed ${maxWidth}x${maxHeight} pixels`,
          });
          setImagePreview(null);
          setFileName('');
        } else {
          clearErrors(name);
          setFileName(file.name);
          const reader = new FileReader();
          reader.onloadend = () => {
            setImagePreview(reader.result as string);
          };
          reader.readAsDataURL(file);
        }
      };
    }
  };

  return (
    <div className="field">
      <span className={`label ${required && !rest.readOnly ? 'required' : ''}`}>
        {label}
      </span>
      <div className="image-upload">
        <label htmlFor="image" className="custom-file-upload">
          <span>{fileName || 'Choose an Image'}</span>
        </label>
        <input
          type="file"
          id="image"
          accept="image/*"
          style={{ display: 'none' }}
          {...rest}
          {...register(name, {
            required: required ? 'Image is required' : undefined,
            onChange: handleImageChange,
          })}
        />
        {error && <p className="has-text-danger">{error}</p>}
      </div>

      {imagePreview && (
        <div>
          <p>Image Preview:</p>
          <img src={imagePreview} alt="Selected" />
        </div>
      )}
    </div>
  );
};

export default MMDImageUploader;
