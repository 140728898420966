import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Form } from '@mrnkr/redux-saga-toolbox';
import { FormEvent, useCallback, useEffect, useState } from 'react';

import { Credential } from 'typings';
import MMDButton from 'components/MMDButton';
import MMDForm from 'components/forms/MMDForm';
import { putDefaultValueSafe } from 'utils/formUtils';
import MMDCheckbox from 'components/forms/MMDCheckbox';
import MMDSelectCat from 'components/forms/MMDSelectCat';
import MMDTextInput from 'components/forms/MMDTextInput';
import { useBoundedActions } from 'hooks/useBoundedActions';
import {
  selectCategories,
  Creators as CategoriesActions,
} from 'modules/category.module';
import {
  selectCredentialsById,
  credentialFormValidator,
  Creators as CredentialActions,
} from 'modules/credentials.module';

const CredentialForm = () => {
  const [categoriesIds, setCategoriesIds] = useState();

  const params = useParams<{ id: string }>();

  const credential = useSelector((state) =>
    selectCredentialsById(state, params.id),
  );

  const Categories = useSelector(selectCategories);

  const { requestCategories } = useBoundedActions(CategoriesActions);

  useEffect(() => {
    requestCategories();
  }, []);

  const renderFormControls = useCallback(
    (
      form: Form,
      onChange: (e: FormEvent<HTMLInputElement | HTMLSelectElement>) => void,
    ) => {
      form.fields['category'].value = categoriesIds;
      return (
        <div className="container p-2">
          <MMDTextInput
            name={form.fields['label'].name}
            value={form.fields['label'].value}
            label="Credential"
            valid={form.fields['label'].valid}
            dirty={form.fields['label'].dirty}
            placeholder="Enter credential name"
            onChange={onChange}
            required={true}
          />
          <MMDTextInput
            name={form.fields['description'].name}
            value={form.fields['description'].value}
            label="Description"
            valid={form.fields['description'].valid}
            dirty={form.fields['description'].dirty}
            placeholder="Enter description"
            onChange={onChange}
            required={true}
          />
          <MMDSelectCat
            name={form.fields['category'].name}
            value={form.fields['category'].value}
            label="Category"
            valid={form.fields['category'].valid}
            dirty={form.fields['category'].dirty}
            categories={credential?.category ?? []}
            options={Categories ?? []}
            onChange={onChange}
            setCategoriesIds={setCategoriesIds}
            required={true}
          />
          <MMDCheckbox
            name={form.fields['npiisrequired'].name}
            label="NPI required"
            dirty={form.fields['npiisrequired'].dirty}
            value={form.fields['npiisrequired'].value as 'true' | 'false'}
            onChange={onChange}
          />
          <MMDButton
            className="mt-2"
            isSubmit
            isPrimary
            text={credential ? 'Update' : 'Create'}
          />
        </div>
      );
    },
    [credential, categoriesIds, Categories],
  );

  if (!credential && params.id) {
    return null;
  }

  const formConfig = {
    formName: params.id
      ? `update-credential-form-${params.id}`
      : 'create-credential-form',
    fields: {
      value: putDefaultValueSafe(credential, 'value'),
      label: putDefaultValueSafe(credential, 'label'),
      description: putDefaultValueSafe(credential, 'description'),
      category: putDefaultValueSafe(credential, 'category'),
      npiisrequired: putDefaultValueSafe(credential, 'npiisrequired'),
    },
    validator: credentialFormValidator,
    onSubmit: (values) =>
      credential && credential.id
        ? CredentialActions.requestUpdateCredential({
            id: credential.id,
            value: values['label'],
            label: values['label'],
            description: values['description'],
            category: values['category'],
            npiisrequired:
              values['npiisrequired'] === '' ? false : values['npiisrequired'],
          })
        : CredentialActions.requestCreateCredential({
            value: values['label'],
            label: values['label'],
            description: values['description'],
            category: values['category'],
            npiisrequired:
              values['npiisrequired'] === '' ? false : values['npiisrequired'],
          } as Credential),
  };

  return <MMDForm {...({ renderFormControls, formConfig } as any)} />;
};

export default CredentialForm;
